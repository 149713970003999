import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function Aboutcard1() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
           Our Mission<span className="purple"> at Cadetship is to </span>
            help<span className="purple"> students and organizations </span>
            <br />by connecting classroom learning
            <br /> with real-world experience.
            Additionally, We offer engaging courses
            <br /> and customized curriculum development 
            <br />to ensure students gain practical skills and confidence 
            <br/>for their future careers.
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Your success Our Guidance!"{" "}
          </p>
          <footer className="blockquote-footer"></footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default Aboutcard1;
