import React from "react";
import './video.css';
function VideoPlayer() {
    return (
      <div className="video-container">
         <div className="about"><p>About US</p></div>
        <video autoPlay loop muted>
          <source src="IMG_0802.MOV" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }
  
  export default VideoPlayer;
  
