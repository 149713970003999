import React from "react";
import { Container, Row, Col,Image } from "react-bootstrap";
import 'aos/dist/aos.css';  // Import AOS styles
import AOS from 'aos';  // Import AOS
import 'bootstrap/dist/css/bootstrap.min.css';
import h11 from "./h11.jpg";
import h22 from "./h22.jpg";
import {
  AiOutlineCheck
} from "react-icons/ai";
AOS.init();


function Home2() {
  return (
    <section id="about-video" className="about-video">
      <Container data-aos="fade-up">
        <Row>
          <Col lg={6} className="video-box align-self-baseline" data-aos="fade-right" data-aos-delay="100">
            <img src={h22} fluid alt="About" className="img-fluid"/> {/* Pic Credit:- pexels-kindelmedia-7688460 */}
            <a 
              href="/about" 
              className="glightbox play-btn mb-4" 
              data-vbtype="video" 
              data-autoplay="true"
            ></a>
          </Col>
          <Col lg={6} className="pt-3 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
            <h3>We provide solutions to all your problems.</h3>
            <p className="fst-italic">
              We deliver IT solutions,tailored to meet your business needs.Our Services include web publishing,digital commerce solutions and software development.
            </p>
            <ul>
              <li style={{textAlign:"left"}}><AiOutlineCheck  style={{fontSize: "30px"}} class="ser-icon"/>Cadetship is a true partner to its clients.</li>
              <li style={{textAlign:"left"}}><AiOutlineCheck  style={{fontSize: "30px"}} class="ser-icon"/>Coherent Communication.</li>
              <li style={{textAlign:"left"}}><AiOutlineCheck  style={{fontSize: "30px"}} class="ser-icon"/>Commitment towards work.</li>
              <li style={{textAlign:"left"}}><AiOutlineCheck  style={{fontSize: "30px"}} class="ser-icon"/>Guidance by experts.</li>
            </ul>
            <p>
             At Cadetship Infotech By focusing on quality, scalability, and user experience, we ensure your business excels in the digital landscape.Our team of experts works closely with you to understand your goals and challenges, providing customized solutions that drive growth and efficiency
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default Home2;
