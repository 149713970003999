import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo1 from "../Assets/logo1.png";
import { BrowserRouter,Link,Route } from "react-router-dom";
import './Navbar.css';
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiFillContacts,
} from "react-icons/ai";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
        <Navbar.Brand href="/" className="d-flex align-items-center">
          <img src={logo1} className="img-fluid logo" id="logo1" alt="brand" />
          <h1 id="webHead"  className="m1-2">CADETSHIP INFOTECH</h1>
        </Navbar.Brand>
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} className="navE" /> <h2 id="navComp">Home</h2>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser style={{ marginBottom: "2px" }} className="navE"/><h2 id="navComp"> About us</h2>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item id="ai">
              <a href="#teams" style={{textDecoration:"none"}}>
                <AiOutlineFundProjectionScreen style={{marginbottom: "2px"}} className="navE"/>{" "}<h2 id="navComp">Services</h2>
              </a>

            </Nav.Item>


            <Nav.Item id="ai">
              <a href="#Contact" style={{textDecoration:"none"}}>
                <AiOutlineUser style={{marginbottom: "2px"}} className="navE"/>{" "}<h2 id="navComp">Contact Us</h2>
              </a>

            </Nav.Item>



          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
