import React from 'react';
import "./Contact.css";


function Contact(){
    return(
  <section id="Contact" class="section-bg" data-scroll-index="7">
    <div class="overlay pt-100 pb-100 ">
      <div class="container">
        <div class="row">
        < div class="col-lg-6 d-flex align-items-center">
          <div class="contact-info">

            <h2 class="contact-title">Get in touch with us</h2>
            <p>"A personal advisor will be in touch by the end of the day to help you navigate your choices effectively".</p>
            <ul class="contact-info">
              <li>
                <div class="info-left">
                  <i class="fas fa-mobile-alt"></i>
                </div>
                <div class="info-right">
                  <h4>+91 9873823612</h4>
                </div>
              </li>
              <li>
                <div class="info-left">
                  <i class="fas fa-at"></i>
                </div>
                <div class="info-right">
                  <h4>cadetshipinfotech@gmail.com</h4>
                </div>
              </li>
              <li>
                <div class="info-left">
                  <i class="fas fa-map-marker-alt"></i>
                </div>
                <div class="info-right">
                  <h4>Delhi, INDIA</h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
          <div class="contact-form">
            <form action="https://api.web3forms.com/submit" id='contact-form' method='POST'>
              <input type='hidden' name='form-name' value='contactForm'/>
              <input type="hidden" name="access_key" value="816d23cc-6910-4c35-bae4-3af0af230218"/>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="user_name" className='labels'>Name</label>
                    <input type="text" name="user_name" class="form-control" id="first-name" placeholder="Enter Your Name-" required/>

                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="email" className='labels'>Email</label>
                    <input type="email" name="email" class="form-control" id="email" placeholder="Enter Your Email-" required/>
                 
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="message" className='labels'>Message</label>
                    <textarea rows="4" type="text" name="message" class="form-control" id="description" placeholder="Enter Your Message-" required></textarea>
    
                  </div>
                </div>
                <div class="col-md-12">
                  <button class="btn-big btn btn-bg">
                    Send Us <i class="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
  )
}

export default Contact