import React from "react";
import {Container} from "react-bootstrap";
import Aboutcard from "./AboutCard";
import Aboutcard1 from "./AboutCard1";
import VideoPlayer from "./video"; 
import logo1 from "../../Assets/logo1.png";

function Aboutus() {
  return (
    <Container fluid className="about-section" id="about">
              {/* <VideoPlayer/> */}
          
      <div className="flex-container">
          <div className="flex-item-left">
            <h1 style={{ fontSize: "2.1em", paddingBottom: "40px",paddingTop:"48px"}}>
              Know Who <strong className="purple">We are</strong>
            </h1>
            <Aboutcard />
          </div>
        <div className="flex-item-right">
        <img src={logo1} className="imgpex"/>
        </div>
      </div>
      <div className="flex-container">
        <div className="flex-item-left">
         <VideoPlayer/>
        </div>
       <div className="flex-item-right" id="flex1">
          <h1 style={{ fontSize: "2.1em", paddingBottom: "40px",paddingTop:"48px"}}>
               Our<strong className="purple">Vision</strong>
          </h1>
            <Aboutcard1/>
       </div>
      </div>
     </Container>

  );
}

export default Aboutus;
