import React from "react";
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Home/Home3.css";
import {
 FaDatabase,
 FaUserShield,
 FaShieldAlt,
 FaServer,
 FaLaptopCode,
 FaAngleDoubleUp
 
} from "react-icons/fa";

const Home3 = () => {
    return (
       <section className="teams" id="teams">
        <Container fluid className="max-width">
            <hr></hr>
            <h2 className="title">Services</h2>
            <hr></hr>
            <Carousel interval={3000}>
                    <Carousel.Item>
                        <Row className="justify-content-center">
                            <div className="d-flex justify-content-center flex-nowrap w-100">
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                        <FaDatabase class="ser-icon"/>
                                    </div>
                                </Col>
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                    <FaServer class="ser-icon"/>
                                    </div>
                                </Col>
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                    <FaShieldAlt class="ser-icon"/>
                                    </div>
                                </Col>
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                    <FaLaptopCode class="ser-icon"/>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </Carousel.Item>
                    <Carousel.Item>
                        <Row className="justify-content-center">
                            <div className="d-flex justify-content-center flex-nowrap w-100">
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                        <FaDatabase class="ser-icon"/>
                                    </div>
                                </Col>
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                    <FaServer class="ser-icon"/>
                                    </div>
                                </Col>
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                    <FaShieldAlt  class="ser-icon"/>
                                    </div>
                                </Col>
                                <Col md={3} className="card mx-2">
                                    <div className="box">
                                    <FaLaptopCode  class="ser-icon"/>
                                    </div>
                                </Col>
                            </div>
                        </Row>
                    </Carousel.Item>
                  
                </Carousel>
                <FaAngleDoubleUp  class="ser-icon"/>
                <h2 id="ser-sec">That's what we do for you !!</h2>
            </Container>
        </section>
        );
    };

export default  Home3;
