import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Home2 from "./Home2";
import Contact from "../Contact";
import Home3 from "./Home3";

function Home() {
  return (
    <section id="h1-section"> 
      <Container fluid className="home-section" id="home">

        <Container className="home-content">
          <Row>
            <Col className="home-header">
              
                <header className="h1-header">
                <h1 className="heading-name">
                  <span>Your Success Our Guidance!</span>
                  
                </h1>
                </header>



            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 style={{ marginTop: '2rem' }}/>
      <Home3 />
      <Contact />
     </section>
  );
}

export default Home;
