import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
           we at<span className="purple"> Cadetship Infotech </span>
            provides<span className="purple"> IT consultancy & Business Solutions </span>
            <br />Our company has proved providing best services to some of the
            <br />world leading organisations.
            Additionally,We provide best Internships in
            <br />some of the leading domains.
            <br />
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Your success Our Guidance!"{" "}
          </p>
          <footer className="blockquote-footer"></footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
